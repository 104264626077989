import { Dispatch } from "@reduxjs/toolkit";
import isEqual from "lodash/isEqual";

import { api } from "@api/apollo";
import { queryTranslationDetails, QueryTranslationDetailsResult, queryTranslations, QueryTranslationsResult } from "@services/translationService";
import { RootState } from "../../store/rootReducer";
import { addTranslationsDetails, addTranslationsInfo } from "./translation.slice";

export const getTranslationDetails = () => async (dispatch: Dispatch, getState: () => RootState) => {
  const { version: oldVersion, allowedLanguages } = getState().translation;
  try {
    const result = await api.client?.query<QueryTranslationDetailsResult>({ query: queryTranslationDetails });

    if (result && (!isEqual(result.data.translationDetailsByProject.version, oldVersion) || !isEqual(result.data.translationDetailsByProject.allowedLanguages, allowedLanguages))) {
      getTranslations(dispatch);

      dispatch(
        addTranslationsDetails({
          version: result.data.translationDetailsByProject.version,
          allowedLanguages: result.data.translationDetailsByProject.allowedLanguages,
        }),
      );
    }
  } catch (e) {
    console.error(e);
  }
};

const getTranslations = async (dispatch: Dispatch) => {
  try {
    const result = await api.client?.query<QueryTranslationsResult>({ query: queryTranslations });

    if (result && result.data.translationsByProject) {
      dispatch(
        addTranslationsInfo({
          translations: result.data.translationsByProject,
        }),
      );
    }
  } catch (e) {
    console.error(e);
  }
};
