import { gql } from "@apollo/client";
import { Event } from "@technis/shared";

export type AllEventsQueryResult = {
  allEvents: Pick<Event, "id" | "name">[];
};

export const ALL_EVENTS_QUERY = gql`
  query {
    allEvents {
      id
      name
    }
  }
`;

export type AllEventsByInstallationQueryResult = {
  allEventsByInstallation: Pick<Event, "id" | "name" | "dateBegin" | "dateEnd">[];
};

export const ALL_EVENTS_BY_INSTALLATION_QUERY = gql`
  query allEventsByInstallation($installationId: Int!) {
    allEventsByInstallation(installationId: $installationId) {
      id
      name
      dateBegin
      dateEnd
    }
  }
`;
