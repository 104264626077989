import React, { useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { ThemeContextProvider } from "../common/Theme";
import { ROUTES } from "./config";
import { NotFound } from "../views/NotFound/NotFound";
import { Login } from "../views/Login/Login";
import { ToastHandler } from "../components/ToastHandler";
import { getTranslationDetails } from "../redux/translation/translation.actions";
import { GlobalSearch } from "@views/GlobalSearch/GlobalSearch";
import { UsersSearch } from "@views/UsersSearch/UsersSearch";
import { LoggedRoute } from "./LoggedRoute";
import { MainLayout } from "@views/MainLayout";
import { InstallationsSearch } from "@views/InstallationsSearch/InstallationsSearch";
import { OrganizationsSearch } from "@views/OrganizationSearch/OrganizationSearch";
import { SsoCallback } from "@views/Login/SsoCallback";
import { WidgetBuilder } from "@views/WidgetBuilder/WidgetBuilder";
import { RootState } from "@store/rootReducer";
import { GlobalSearchProvider } from "@views/GlobalSearch/GlobalSearchContext";

export const AppRoutes = () => {
  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTranslationDetails());
  }, []);

  return (
    <ThemeContextProvider setRoot>
      <GlobalSearchProvider>
        <BrowserRouter>
          <ToastHandler />
          <Routes>
            <Route path={ROUTES.SSO_CALLBACK} element={<SsoCallback />} />
            <Route path={ROUTES.LOGIN} element={<Login />} />
            <Route element={<MainLayout />}>
              <Route
                path={ROUTES.ROOT}
                element={
                  <LoggedRoute>
                    <GlobalSearch />
                  </LoggedRoute>
                }
              />
              <Route
                path={ROUTES.USERS}
                element={
                  <LoggedRoute>
                    <UsersSearch />
                  </LoggedRoute>
                }
              />
              <Route
                path={ROUTES.INSTALLATIONS}
                element={
                  <LoggedRoute>
                    <InstallationsSearch />
                  </LoggedRoute>
                }
              />
              <Route
                path={ROUTES.ORGANIZATIONS}
                element={
                  <LoggedRoute>
                    <OrganizationsSearch />
                  </LoggedRoute>
                }
              />
            </Route>
            <Route
              path={ROUTES.WIDGET_BUILDER}
              element={
                <LoggedRoute>
                  <WidgetBuilder />
                </LoggedRoute>
              }
            />
            <Route path={ROUTES.ALL} element={<Navigate to={isLoggedIn ? ROUTES.ROOT : ROUTES.LOGIN} />} />
            <Route path={ROUTES.NOT_FOUND} element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </GlobalSearchProvider>
    </ThemeContextProvider>
  );
};
