import { ApolloQueryResult, OperationVariables, QueryOptions, DocumentNode, MutationOptions, FetchResult } from "@apollo/client";
import { api } from "../api/apollo";
import { removeUndefined } from "../utils/utils";

export const client = {
  query: (query: DocumentNode, variables: OperationVariables = {}, options?: Omit<QueryOptions<OperationVariables>, "query" | "variables">) =>
    (api.client as NonNullable<typeof api.client>).query({
      ...(options || {}),
      query,
      variables,
    }),
  mutate: (mutation: DocumentNode, variables: OperationVariables = {}, options?: Omit<MutationOptions<OperationVariables>, "variables">) =>
    (api.client as NonNullable<typeof api.client>).mutate({
      ...(options || {}),
      mutation,
      variables: removeUndefined(variables),
    }),
};

export type Query<T> = ApolloQueryResult<T>;
export type Mutation<T> = FetchResult<Partial<T>>;
