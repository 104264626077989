import { Indexable, isDefined } from "@technis/shared";
import { get } from "lodash";
import { isObject } from "../utils/utils";
import { i18n, TranslationOption } from "./i18n";
import { translation } from "./translation";

const defaultPluralization = (count: number) => {
  switch (count) {
    case 0:
      return "zero";
    case 1:
      return "one";
    case 2:
      return "two";
    default:
      return "other";
  }
};

const getPluralization = (count: number) => {
  switch (i18n.language) {
    case "en":
    default:
      return defaultPluralization(count);
  }
};

const getKey = (key: string | Indexable<string>) => {
  if (typeof key === "string") return key;
  return key?.count;
};

export const getPlural = (params: TranslationOption) => {
  const { key, count } = params;

  if (Array.isArray(key)) {
    const translationKey = key.map((key) => get(translation, getKey(key)));
    const newKeys = translationKey.map((key) => {
      if (!isObject(key)) return key;
      if (!count) return key.one;
      return key[getPluralization(count)];
    });
    return { ...params, key: newKeys };
  }

  const translationKey = get(translation, getKey(key));
  if (!isObject(translationKey)) return { ...params, key: key as string | string[] };
  if (!isDefined(count)) return { ...params, key: translationKey.one };
  return { ...params, key: translationKey[getPluralization(count)], options: { ...params.options, count: params.count } };
};
