import thunk from "redux-thunk";
import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";

import { persistConfig } from "./persistConfigs";
import { rootReducer } from "./rootReducer";

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middlewares = [thunk];

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(middlewares),
});

const persistor = persistStore(store);

export { store, persistor };
