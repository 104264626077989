import { AnyAction, combineReducers, Reducer } from "@reduxjs/toolkit";

import { initialState as initialAuthState, authReducer as auth } from "../redux/auth/auth.slice";
import { initialState as initialToastState, toastReducer as toast } from "../redux/toast/toast.slice";
import { initialState as initialTranslationState, translationReducer as translation } from "../redux/translation/translation.slice";

export const initialRootState = {
  auth: initialAuthState,
  toast: initialToastState,
  translation: initialTranslationState,
};

export type RootState = typeof initialRootState;

export const rootReducer: Reducer<RootState, AnyAction> = combineReducers({
  auth,
  toast,
  translation,
});
