import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ToastMessage } from "@technis/ui";

export interface ToastState {
  toasts: ToastMessage[];
}

export const initialState: ToastState = {
  toasts: [],
};

export const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    displayToast: (state, { payload }: PayloadAction<ToastMessage>) => {
      state.toasts = [...state.toasts, payload];
    },
    closeToast: (state, { payload }: PayloadAction<string>) => {
      state.toasts = state.toasts.filter((toast) => toast.id != payload);
    },
  },
});

export const { displayToast, closeToast } = toastSlice.actions;
export const toastReducer = toastSlice.reducer;
