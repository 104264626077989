import { gql } from "@apollo/client";
import { Invitation, InvitationInput } from "@technis/shared";
import { client, Mutation } from "./index";

export const invitationData = `
  id
  email
  role
  status
  organizationId
  organizationName
  createdDate
  sentDate
  expirationDate
  acceptedDate
  revokedDate
`;

const CREATE_INVITATION_MUTATION = gql`
  mutation createInvitation($invitation: InvitationInput!) {
    createInvitation(invitation: $invitation) {
      ${invitationData}
    }
  }
`;

export type CreateInvitationResult = Mutation<{ createInvitation: Invitation }>;

export const InvitationService = {
  createInvitation: (invitation: InvitationInput): Promise<CreateInvitationResult> => client.mutate(CREATE_INVITATION_MUTATION, { invitation }),
};
