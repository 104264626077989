import { gql } from "@apollo/client";
import { Right } from "@technis/shared";

export const RIGHT_BY_ID_QUERY = gql`
  query rightById($rightId: Int!) {
    rightById(rightId: $rightId) {
      id
      userIds
      organizationId
      rightsRaw
    }
  }
`;

export const queryRightById = (rightId: number) => `
  rightById(rightId: ${rightId}) {
    id
    userIds
    organizationId
    rightsRaw
  }
`;

export type RightByIdQueryResult = {
  rightById: Right;
};
