import { gql } from "@apollo/client";
import { Organization } from "@technis/shared";

export const ALL_ORGANIZATIONS_QUERY = gql`
  query {
    allOrganizations {
      id
      name
    }
  }
`;

export const ORGANIZATION_CONNECTION_QUERY = gql`
  query organizationConnection($searchValue: String) {
    organizationConnection(first: 10, filter: { searchValue: $searchValue }) {
      count
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          name
          address
          creationDate
        }
        cursor
      }
    }
  }
`;

export const GET_ORGANIZATION_INFO_QUERY = gql`
  query getOrganizationInfo($organizationId: Int!) {
    getOrganizationInfo(organizationId: $organizationId) {
      organizationName
      organizationId
      organizationAddress
      users {
        id
        name
        email
      }
      devices {
        id
        name
        lastStatus
        lastConnectionDate
        passageId
        passageName
        zoneId
        zoneName
        installationId
        installationName
      }
      installations {
        id
        name
        address
      }
    }
  }
`;

export type AllOrganizationsQueryResult = {
  allOrganizations: Pick<Organization, "id" | "name">[];
};

export type OrganizationConnectionEdge = {
  node: {
    id: number;
    name: string;
    address: string;
    creationDate: number;
  };
  cursor: string;
};

export type OrganizationConnectionQueryResult = {
  organizationConnection: {
    count: number;
    pageInfo: {
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
    edges: OrganizationConnectionEdge[];
  };
};

export type GetOrganizationDataQueryResult = {
  getOrganizationInfo: {
    organizationName: string;
    organizationId: number;
    organizationAddress: string;
    users: {
      id: number;
      name: string;
      email: boolean;
    }[];
    devices: {
      id: number;
      name: string;
      lastStatus: string;
      lastConnectionDate: number;
      passageId: number;
      passageName: string;
      zoneId: number;
      zoneName: string;
      installationId: number;
      installationName: string;
    }[];
    installations: {
      id: number;
      name: string;
      address: boolean;
    }[];
  };
};
