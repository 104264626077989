import { useQuery } from "@apollo/client";
import { ErrorResponse } from "@apollo/client/link/error";
import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { ROUTES } from "./config";
import { RootState } from "../store/rootReducer";
import { allApolloErrorsToString } from "../api/utils";
import { loginError, loginSuccess, saveUser } from "../redux/auth/auth.slice";
import { ME_DATA_QUERY, MeQueryData } from "../services/userService";
import { AuthService } from "../services/authService";
import { useTranslation } from "react-i18next";

export const LoggedRoute = ({ children }: { children: JSX.Element }) => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);

  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
  const token = useSelector((state: RootState) => state.auth.token);

  const { data } = useQuery<MeQueryData>(ME_DATA_QUERY);
  const meData = data?.me;

  if (!!meData?.lang && i18n.language !== meData.lang) {
    i18n.changeLanguage(meData.lang);
  }

  useEffect(() => {
    if (!isLoggedIn && token) {
      AuthService.renewToken()
        .then((data) => {
          const token = data.data.renew;
          dispatch(loginSuccess(token));
          meData && dispatch(saveUser(meData));
        })
        .catch((error) => {
          dispatch(loginError(allApolloErrorsToString(error as ErrorResponse)));
        })
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  }, []);

  if (isLoggedIn) {
    return children;
  }

  return <Navigate to={ROUTES.LOGIN} state={{ from: location }} replace />;
};
