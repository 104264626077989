import { mergeMultipleQueries } from "./../utils/utils";
import { client } from "./index";
import { Access, ID } from "@technis/shared";
import { ApolloQueryResult } from "@apollo/client";

const accessData = `
  id
  zoneIds
  installationId
  interfaceId
  eventIds
`;

export const accessByIdQuery = (accessId: ID) => `
  accessById(accessId: ${accessId}) {
    ${accessData}
  }
`;

export type AccessByIdQueryResult = {
  accessById: Access;
};

export interface MultipleQueriesResult<T> {
  [key: string]: T;
}

export const AccessService = {
  accessByIds: (accessIds: ID[]): Promise<ApolloQueryResult<MultipleQueriesResult<Access>>> => {
    const query = mergeMultipleQueries(accessIds.map((accessId) => accessByIdQuery(accessId)));

    return client.query(query, { fetchPolicy: "network-only" });
  },
};
