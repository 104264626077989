import { gql } from "@apollo/client";
import { ProjectTypes, Translation } from "@technis/shared";

export const queryTranslationDetails = gql`
  query translationDetailsByProject{
    translationDetailsByProject(project: ${ProjectTypes.ADMIN}) {
      version
      allowedLanguages
      languages
    }
  }
`;

export const queryTranslations = gql`
  query translationsByProject{
    translationsByProject(project: ${ProjectTypes.ADMIN})
  }
`;

export interface QueryTranslationDetailsResult {
  translationDetailsByProject: Translation;
}

export interface QueryTranslationsResult {
  translationsByProject: object;
}
