export const ROUTES = {
  ROOT: "/",
  LOGIN: "/login",
  USERS: "/users",
  SSO_CALLBACK: "/sso-callback",
  INSTALLATIONS: "/installations",
  ORGANIZATIONS: "/organizations",
  NOT_FOUND: "/not-found",
  WIDGET_BUILDER: "/widget-builder",
  ALL: "*",
};
