export const translation = {
  common: {
    download: "common.download",
    downloadErrorMessage: "common.downloadErrorMessage",
    downloadSuccessMessage: "common.downloadSuccessMessage",
  },
  errors: {
    notFound: "errors.notFound",
    noServer: "errors.noServer",
  },
  login: {
    commonError: "login.commonError",
    wrongCredentials: "login.wrongCredentials",
    copyright: "login.copyright",
    logout: "login.logout",
  },
  loginOtp: {
    title: "loginOtp.title",
    description: "loginOtp.description",
    email: "loginOtp.email",
    phone: "loginOtp.phone",
    resend: "loginOtp.resend",
    invalidCode: "loginOtp.invalidCode",
    dontAskAgain: "loginOtp.dontAskAgain",
    goBack: "loginOtp.goBack",
  },
  search: {
    search: "search.search",
    users: "search.users",
    email: "search.email",
    organization: "search.organization",
    organizations: "search.organizations",
    status: "search.status",
    id: "search.id",
    actions: "search.actions",
    edit: "search.edit",
    delete: "search.delete",
    viewAsUser: "search.viewAsUser",
    selectViewAsOption: "search.selectViewAsOption",
    viewAsOptions: {
      legacy: "search.viewAsOptions.legacy",
      retail: "search.viewAsOptions.retail",
    },
    installations: "search.installations",
    installationData: "search.installationData",
    supportData: "search.supportData",
    address: "search.address",
    creationDate: "search.creationDate",
    organizationData: "search.organizationData",
    widgetBuilder: "search.widgetBuilder",
  },
  installation: {
    dateRange: "installation.dateRange",
    send: "installation.send",
    event: "installation.event",
    installationInfo: "installation.installationInfo",
    createSupportDataRequestErrorMessage: "installation.createSupportDataRequestErrorMessage",
    createSupportDataRequestSuccessMessage: "installation.createSupportDataRequestSuccessMessage",
  },
  users: {
    create: "users.create",
    invite: "users.invite",
    bulkInvite: "users.bulkInvite",
    invitationModalTitle: "users.invitationModalTitle",
    bulkInviteModalTitle: "users.bulkInviteModalTitle",
    dashboard: "users.dashboard",
    organization: "users.organization",
    email: "users.email",
    emails: "users.emails",
    phone: "users.phone",
    defaultProfile: "users.defaultProfile",
    group: "users.group",
    language: "users.language",
    cancel: "users.cancel",
    sendInvitation: "users.sendInvitation",
    sendInvitations: "users.sendInvitations",
    next: "users.next",
    accountName: "users.accountName",
    telephone: "users.telephone",
    theme: "users.theme",
    twoFA: "users.twoFA",
    twoFAVerification: "users.twoFAVerification",
    generalInformation: "users.generalInformation",
    rights: "users.rights",
    accesses: "users.accesses",
    add: "users.add",
    save: "users.save",
    user: "users.user",
    selectAll: "users.selectAll",
    rightAlert: "users.rightAlert",
    rightAtmosphere: "users.rightAtmosphere",
    rightCarto: "users.rightCarto",
    rightDefault: "users.rightDefault",
    rightEvent: "users.rightEvent",
    rightInterface: "users.rightInterface",
    rightLink: "users.rightLink",
    rightPad: "users.rightPad",
    rightPlanning: "users.rightPlanning",
    rightReport: "users.rightReport",
    rightSchedule: "users.rightSchedule",
    rightStopNGo: "users.rightStopNGo",
    rightTemplate: "users.rightTemplate",
    rightUser: "users.rightUser",
    rightWaitingTime: "users.rightWaitingTime",
    installations: "users.installations",
    createInvitationSuccessMessage: "users.createInvitationSuccessMessage",
    createInvitationErrorMessage: "users.createInvitationErrorMessage",
    mutateUserSuccessMessage: "users.mutateUserSuccessMessage",
    mutateUserErrorMessage: "users.mutateUserErrorMessage",
    deleteUserConfirmation: "users.deleteUserConfirmation",
    deleteUserSuccessMessage: "users.deleteUserSuccessMessage",
    deleteUserErrorMessage: "users.deleteUserErrorMessage",
  },
  languages: {
    fr: "languages.fr",
    en: "languages.en",
    it: "languages.it",
  },
  theme: {
    light: "theme.light",
    dark: "theme.dark",
  },
  widgetBuilder: {
    run: "widgetBuilder.run",
    title: "widgetBuilder.title",
    load: "widgetBuilder.load",
    publish: "widgetBuilder.publish",
    published: "widgetBuilder.published",
    save: "widgetBuilder.save",
    saved: "widgetBuilder.saved",
    saveAs: "widgetBuilder.saveAs",
    saveAsPrompt: "widgetBuilder.saveAsPrompt",
    savingError: "widgetBuilder.savingError",
    aiGenerate: "widgetBuilder.aiGenerate",
    aiPlaceholder: "widgetBuilder.aiPlaceholder",
  },
};
